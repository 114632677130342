<template>
  <div class="lang-select-wrapper" tag="div">
    <b-select
      id="page-lang"
      v-model="selectedLang"
      @input="updateSelectedLang()"
    >
      <option value="en">English</option>
      <option value="de">Deutsch</option>
      <option value="es">Espanol</option>
      <option value="fr">French</option>
      <option value="tr">Turkish</option>
      <option value="ro">Romanian</option>
    </b-select>
  </div>
</template>

<script>
import { loadLanguageAsync } from '@/utils/i18n-setup';
import { localize } from 'vee-validate';

export default {
  data() {
    return {
      event: null,
      selectedLang: 'en',
    };
  },
  methods: {
    updateSelectedLang() {
      this.$cookies.set(
        `${this.$route.params.slug}::lang`,
        this.selectedLang,
        null,
        null,
        'livecasthq.com',
        true,
        'Lax',
      );
      loadLanguageAsync(this.selectedLang);
      localize(this.selectedLang);
      this.$moment.locale(this.selectedLang);
      this.$emit('selectedLangChange', this.selectedLang);
    },
  },
  mounted() {
    this.event = this.$root.event;
    loadLanguageAsync(this.event.interfaceLang);
    this.selectedLang = this.event.interfaceLang;
    this.$moment.locale(this.selectedLang);
  },
  //
};
</script>

<style lang="scss">
.navbar {
  background-color: transparent !important;
  position: absolute;
  align-content: flex-end;
  width: 100%;
  &-menu {
    background-color: transparent;
  }
}
.lang-select-wrapper{
  position: absolute;
  justify-content: flex-end;
  display: flex;
  padding: 1rem;
  width: 100%;
  z-index: 1000;
}
</style>
